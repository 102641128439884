import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {EcaseHttpService} from './ecase-http.service';
import {MatProgressBarService} from './mat-progress-bar.service';
import {TitleService} from './title.service';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {map} from 'rxjs/operators';
import {ECaseNavigationModel} from "../model/navigation.model";
import {ECaseNavigationService} from "./navigation.service";


@Injectable({
  providedIn: 'root'
})
export class LoginAuthenticationService implements CanActivate {
  isUserLoggedIn: boolean;
  isSamlProfile: boolean;
  model: any[];
  roles;
  tabs;
  isContainApplicantRole: boolean;
  isOnlyApplicantRole: boolean;
  isUserMasquerading: boolean;
  firstClickableFeature;
  isProfileCompleted: string;
  featureIdList = [];
  sysActionIdList = [];
  primeRole;
  dummyRole;
  userProfile: any;
  masqueradedUserProfile: any;
  refreshUrl;
  hasUrlRefreshed: boolean;
  isDevEnvironment: boolean;
  urlArray = [];
  baseUrl = '';
  isSfuProfile;
  parameters;
  isFirstTimeLogin: boolean;
  syntoDateFormat;
  isUserRedirectedForLogIn = false;
  oldRedirectedUrl = '';
  isAllPrivacyNoticesConsented: boolean;
  isPublicDocumentationDocsAvailable: boolean;
  privacyNoticeConsentDetails = {
    'ssoRoleId' : -1,
    'ssoConsentId' : -1,
  };
  // the profile page will use this subjet to notify when the user saved their profile and no longer considere first login
  public firstLoginchanged: Subject<any> = new Subject<any>();
  public userLogged;
  private darkModeSubject = new Subject<boolean>();
  private masqueradeSubject = new Subject<boolean>();


  constructor(private router: Router, private eCaseNavigationService: ECaseNavigationService, private eCaseHttpService: EcaseHttpService,
              private matProgressBarService: MatProgressBarService, private titleService: TitleService,
              private translate: TranslateService, private cookieService: CookieService) {
  }

  get toggleDarkMode(): Observable<boolean> {
    return this.darkModeSubject.asObservable();
  }

  checkForDarkModeSubject(response): void {
    this.darkModeSubject.next(response);
  }

  get onMasqueradeChange(): Observable<boolean> {
    return this.masqueradeSubject.asObservable();
  }

  emitMasqueradeChangeEvent(response): void {
    this.masqueradeSubject.next(response);
  }

  public authenticateCredentials(username, password, cookies): Observable<any> {
    return this.eCaseHttpService.post('/api/authenticateCredentials', {
      'username': username,
      'password': password,
      'cookies': cookies,
      'lang': this.translate.getDefaultLang()
    });
  }

  public lockOrUnlockUserAccount(username, status): Observable<any> {
    return this.eCaseHttpService.post('/api/lockOrUnlockUserAccount', {'username': username, 'status': status});
  }

  public getComplementaryInformationFormDataForFields(formId: number): Observable<any> {
    return this.eCaseHttpService.post('/api/getComplementaryInformationFormDataForFields', {
      'formId': formId,
      'caseJsonDataRepositoryId': -1,
      'isFormForConsent': true
    });
  }

  public saveComplementaryInfoForm(prj: any, selectedSection: string): Observable<any> {
    return this.eCaseHttpService.post('/api/saveComplementaryInformationForm', {
      'initialQuestionForm': prj,
      'otherForm': prj,
      'caseJsonDataRepositoryId': -1,
      'selectedSection': selectedSection,
      'isFormForConsent': true
    });
  }

  public resetLoginCounter(): Observable<any> {
    return this.eCaseHttpService.get('/api/resetLoginCounter');
  }

  public getUserConsents(): Observable<any> {
    return this.eCaseHttpService.get('/api/getUserConsents');
  }

  public getPublicDocuments(): Observable<any> {
    return this.eCaseHttpService.get('/api/getPublicDocuments');
  }

  public giveConsentToPrivacyNotice(ssoConsentId: number, isAccepted: boolean, ssoRoleId: number): Observable<any> {
    return this.eCaseHttpService.post('/api/giveConsentToPrivacyNotice', {
      'ssoConsentId' : ssoConsentId,
      'ssoRoleId' : ssoRoleId,
      'isAccepted' : isAccepted
    });
  }

  public getPrivacyNoticeDetails(ssoConsentId: number, ssoRoleId: number): Observable<any> {
    return this.eCaseHttpService.post('/api/getPrivacyNoticeDetails', {
      'ssoConsentId' : ssoConsentId,
      'ssoRoleId' : ssoRoleId
    });
  }

  public logOut(): Observable<any> {
    this.privacyNoticeConsentDetails = {
      'ssoRoleId' : -1,
      'ssoConsentId' : -1,
    };
    this.isUserLoggedIn = false;
    this.isUserRedirectedForLogIn = false;
    return this.eCaseHttpService.get('/api/logOut', {responseType: 'text'});
  }

  public createAccountAndSendActivationLink(user): Observable<any> {
    return this.eCaseHttpService.post('/api/createAccountAndSendActivationLink', {'user': user});
  }


  public activateAccount(activationCode): Observable<any> {
    return this.eCaseHttpService.post('/api/activateAccount', {'activationCode': activationCode});
  }

  public getSaml2UserProfile(): Observable<any> {
    return this.eCaseHttpService.get('/api/getSaml2UserProfile');
  }

  public getRolesForShibboleth(response): Observable<any> {
    return this.eCaseHttpService.post('/api/getRolesForShibboleth', {'response': response});
  }

  public updateProfile(isApplicantRoleLevelProfileCompleted): Observable<any> {
    return this.eCaseHttpService.post('/api/updateUserProfile ', {
      'isFirstTimeLogin': true,
      'isApplicantRoleLevelProfileCompleted': isApplicantRoleLevelProfileCompleted
    });
  }

  public updateProfileSfu(userProfile): Observable<any> {
    return this.eCaseHttpService.post(' /api/updateProfile ', userProfile);
  }

  public updateDefaultLanguageForUser(lang): Observable<any> {
    return this.eCaseHttpService.post(' /api/updateDefaultLanguageForUser', {'lang': lang});
  }

  public enableUserMasquerading(ssoUserId): Observable<any> {
    return this.eCaseHttpService.get('/api/enableUserMasquerading/' + ssoUserId);
  }

  public endUserMasquerading(): Observable<any> {
    return this.eCaseHttpService.get('/api/endUserMasquerading');
  }

  public addDashBoards(tab): any {
    this.model = [{
      'id': 'applications',
      'title': 'Applications',
      'translate': 'APPLICATIONS',
      'itemType': 'group',
      'icon': 'apps',
      'children': []
    }];
    this.model[0].children.push(tab);
    this.model = this.addMailToNavigationModel(this.model);
    return this.model;
  }

  addMailToNavigationModel(navModel): any {
    return navModel;
  }

   checkIfUserIsLoggedIn(): Observable<any> {
    return this.eCaseHttpService.post('/api/isUserLoggedIn', this.cookieService.getAll());
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.refreshUrl = state.url;
    this.urlArray.push(this.refreshUrl);
    this.hasUrlRefreshed = true;
    this.matProgressBarService.show();
    return this.checkIfUserIsLoggedIn().pipe(map((data) => {
      this.userLogged = data.id;
      const validationResult: any = data;

      this.isUserLoggedIn = validationResult.status;
      if (this.isUserLoggedIn && validationResult.tabs.length !== 0) {
        this.isUserRedirectedForLogIn = false;
        this.userProfile = validationResult.userProfile;
        this.translate.use(this.userProfile.principalLanguageCode);
        this.translate.setDefaultLang(this.userProfile.principalLanguageCode);
        this.isSfuProfile = validationResult.userProfile.email.endsWith('@sfu');
        this.primeRole = validationResult.primeRole;
        this.roles = validationResult.roles;
        this.featureIdList = validationResult.featureIdList;
        this.isProfileCompleted = validationResult.isProfileCompleted;
        this.isUserMasquerading = validationResult.isUserMasquerading;
        this.masqueradedUserProfile = validationResult.userProfile;
        this.emitMasqueradeChangeEvent({
          'isUserMasquerading' : this.isUserMasquerading,
          'masqueradedUserProfile' : this.masqueradedUserProfile,
          'originalUserProfile' : validationResult.originalUserProfile
        });
        this.isSamlProfile = validationResult.isSamlProfile;
        this.isAllPrivacyNoticesConsented = validationResult.isAllPrivacyNoticesConsented;
        this.privacyNoticeConsentDetails = {
          'ssoRoleId' : validationResult.ssoRoleId,
          'ssoConsentId' : validationResult.ssoConsentId
        };
        this.sysActionIdList = validationResult.sysActionIdList;
        this.eCaseNavigationService.sideNavTabs = validationResult.tabs;
        this.tabs = validationResult.tabs;
        this.firstClickableFeature = validationResult.firstClickableFeature;
        this.isFirstTimeLogin = validationResult.isFirstTimeLogin;
        this.isContainApplicantRole = validationResult.isContainApplicantRole;
        this.isOnlyApplicantRole = validationResult.isOnlyApplicantRole;
        this.isPublicDocumentationDocsAvailable = validationResult.isPublicDocumentationDocsAvailable;
        if (state.url.includes('/dashboard/reviewerdashboard')) {
          validationResult.primeRole = 5;
        } else if (state.url.includes('/dashboard/researcherdashboard')) {
          validationResult.primeRole = 3;
        } else if (state.url.includes('/dashboard/admindashboard')) {
          validationResult.primeRole = 2;
        } else if (state.url.includes('/dashboard/institutionaldashboard')) {
          validationResult.primeRole = 4;
        }
        this.dummyRole = validationResult.primeRole;
        console.log('can activate');
        this.eCaseNavigationService.setNavigationModel(new ECaseNavigationModel(this.addDashBoards(this.tabs.filter(tab => tab.roleId === validationResult.primeRole)[0])));
        console.log(this.tabs.filter(tab => tab.roleId === validationResult.primeRole)[0]);
        this.matProgressBarService.hide();
      } else if (!this.isUserLoggedIn) {
        this.oldRedirectedUrl = state.url;
        this.isUserRedirectedForLogIn = true;
        console.log('You are not logged in');
        this.router.navigate(['/']);
        this.titleService.setTitleWithSeparator(['ecase.common.defaultBrowserTabTitle']);
      }
      this.matProgressBarService.hide();
      return this.isUserLoggedIn;
    }));
  }

  getAllUniversityFaculty(): Observable<any> {
    return this.eCaseHttpService.get('/api/getAllOrganisation ');
  }


  getPrefUser(): Observable<any> {
    return this.eCaseHttpService.get('/api/getPrefUser ');
  }

  setNewPassword(resetPassWordObj): Observable<any> {
    return this.eCaseHttpService.post('/api/updatePass', resetPassWordObj);
  }

  getRolesForUser(): Observable<any> {
    return this.eCaseHttpService.get('/api/getRoleForUser ');
  }
}
