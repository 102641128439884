import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ActivatorComponent} from './login/activator/activator.component';
import {ECaseResetPasswordComponent} from './login/authentication/reset-password/reset-password.component';
import {ShibbolethLoginComponent} from './login/shibboleth-login/shibboleth-login.component';
import {LoginComponent} from './login/login.component';
import {ECaseForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ECaseRegisterComponent} from './login/authentication/register/register.component';
import {
  TwoFactorAuthenticationComponent
} from './login/authentication/two-factor-authentication/two-factor-authentication.component';
import {AdfsSaml2LoginComponent} from './login/adfs-saml2-login/adfs-saml2-login.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {BrowserSupportComponent} from './browser-support/browser-support.component';
import {ErrorCodeScreenComponent, LoginAuthenticationService} from 'synto-common';
import {GeneralDirectoryComponent} from './general-directory/general-directory.component';
import {NotifierComponent} from './notifier/notifier.component';

const routes: Routes = [
  {
    path: 'shibboSecure',
    component: ShibbolethLoginComponent
  }, {
    path: 'error',
    component: ErrorCodeScreenComponent
  },
  {
    path: 'activate/:activationCode/:lang',
    component: ActivatorComponent,
  },
  {
    path: 'resetPassword/:activationCode/:lang',
    component: ECaseResetPasswordComponent
  },
  {
    path: 'auth',
    component: LoginComponent,
  },
  {
    path: 'login/:lang',
    component: LoginComponent,
  },
  {
    path: 'auth/2fa',
    component: TwoFactorAuthenticationComponent,
  },
  {
    path: 'saml2Login',
    component: AdfsSaml2LoginComponent,
  },
  {
    path: 'auth/register',
    component: ECaseRegisterComponent
  },
  {
    path: 'forgotPassword',
    component: ECaseForgotPasswordComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'browser-support',
    component: BrowserSupportComponent
  },
  {
    path: 'general-directory',
    component:GeneralDirectoryComponent
  },
  {
    path: 'dashboard',
    data: {breadcrumb: 'HOME'},
    canActivate: [LoginAuthenticationService],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.ECaseDashboardModule)
  },
  {
    path: 'notify/:code',
    component: NotifierComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })
  ],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule {
}
